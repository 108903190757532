<template>
    <v-card class="mx-auto" outlined>
            <v-card-title>Dashboard</v-card-title>
            <v-container fluid>
                <line-chart
                    v-if="loaded"
                    :chartdata="chartdata"
                    :options="options"/>
            </v-container>
    </v-card>
</template>

<script>
import LineChart from './chart/Chart.vue'

export default {
    name: 'dashboard',
    title: 'dashboard',

    components: { LineChart },

    data: () => ({
    loaded: false,
    chartdata: {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      datasets: [
        {
          label: 'Casual',
          backgroundColor: '#C8B8DB ',
          data: [10, 0]
        },

        {
          label: 'Fleet',
          backgroundColor: '#CDE7B0',
          data: [32, 0]
        },

        {
          label: 'Season',
          backgroundColor: '#A3BFA8',
          data: [40, 0]
        }
      ]
    },

    options: {
      responsive: true,
      maintainAspectRatio: false
    }
  }),
  async mounted () {
    this.loaded = false
    try {
      //const { userlist } = await fetch('/api/userlist')
      //this.chartdata = userlist
      this.loaded = true
    } catch (e) {
      console.error(e)
    }
  }
    
}
</script>

<style lang="scss" scoped>
    .dashboard-card{
        flex: 1 0 21%; /* explanation below */
    }

    .doc-card-title{
        margin-left: -24px;
        padding: 2px 10px 2px 24px;
        background: #e0e0e0;
        color: #616161;
        position: relative;
        border-radius: 3px 5px 5px 0;
    }

    .slide-fade-enter-active {
  transition: all .9s ease;
}
.slide-fade-leave-active {
  transition: all .12s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(-50px);
  opacity: 0;
}

.slide-fade-leave-to{
	transform: translateX(50px);
  	opacity: 0;
}

.grow { transition: all .2s ease-in-out; }
.grow:hover { transform: scale(1.05); }

.user-select-none{
    user-select: none;
}
</style>